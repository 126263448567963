import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_ARTICLE_MEDICAL_PAYMENT_HISTORY,
  GET_ARTICLE_MEDICAL_PAYMENT_HISTORIES,
  STORE_ARTICLE_MEDICAL_PAYMENT_HISTORY,
  UPDATE_ARTICLE_MEDICAL_PAYMENT_HISTORY
} from "@/store/modules/article.medical.payment.history.module";
export  default {
  methods: {
    storeArticleMedicalPaymentHistory(article_id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_ARTICLE_MEDICAL_PAYMENT_HISTORY, { article_id, formData }), actions)
    },
    getArticleMedicalPaymentHistory(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_MEDICAL_PAYMENT_HISTORY, id), actions)
    },
    updateArticleMedicalPaymentHistory(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_ARTICLE_MEDICAL_PAYMENT_HISTORY, { id, formData }), actions)
    },
    getArticleMedicalPaymentHistories(article_id, page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_MEDICAL_PAYMENT_HISTORIES, { article_id, page }), actions)
    }
  }
}
